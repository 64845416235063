const THL_logos = {
    '64944': 'pit-vipers.png',
    '112355': 'wanderers.png',
    '190215': 'north-side-storm.png',
    '110132': 'comet-crushers.png',
    '142719': 'forsworn-knights.png',
    '175025': 'bandits.png',
    '192899': 'roaring-tigers.png',
    '19689': 'flaming-toros.png',
    // Add more mappings as necessary
};

export default THL_logos;