import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Divider, Link } from '@mui/material';
import LogoButtonWithIcon from '../LogoButtonWithIcon';
function Demo_Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const BoxStyle = {
        padding: windowWidth < 600 ? '10px 0px 10px 0px' : windowWidth < 900 ? '20px' : '20px',
    };

    const ContainerStyle = {
        padding: '0px',
    };

    const logoPath = require(`./Logos/THL_Logo.png`);

    return (
        <Container>
            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '50px' }}> {/* my={4} adds margin to the top and bottom */}
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

                    <Typography variant="h5" color="white" fontWeight="800" gutterBottom>
                        Welcome to the Demo!
                    </Typography>
                </Box>
            </Box>

            <Box my={4} sx={{backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>
                <Typography variant="h5" color="white" fontWeight="800" gutterBottom>
                    Explore Stats
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the stats for the Demo!
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                    <Button variant="contained" color="primary" href="/Demo/standings">
                        Standings
                    </Button>
                    <Button variant="contained" color="primary" href="/Demo/skater-leaderboard" sx={{marginTop: 1 }}>
                        Skater Leaderboard
                    </Button>
                    <Button variant="contained" color="primary" href="/Demo/goalie-leaderboard" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0  }}>
                        Goalie Leaderboard
                    </Button>
                </Box>
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px' }} style={BoxStyle}>
                <Typography variant="h5" color="white" fontWeight="800" gutterBottom sx={{marginLeft: 1}}>
                    Explore Teams
                </Typography>
                <Typography color="white" paragraph sx={{fontSize: '14px',marginLeft: 1}}>
                    Check out the teams!
                </Typography>
                <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} style={ContainerStyle}>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'pit-vipers'} text={'Pit Vipers'} league={'Demo'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'wanderers'} text={'Wanderers'} league={'Demo'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'north-side-storm'} text={'North Side Storm'} league={'Demo'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'comet-crushers'} text={'Comet Crushers'} league={'Demo'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                    </Container>
                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'forsworn-knights'} text={'Forsworn Knights'} league={'Demo'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'bandits'} text={'Bandits'} league={'Demo'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'roaring-tigers'} text={'Roaring Tigers'} league={'Demo'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <LogoButtonWithIcon team={'flaming-toros'} text={'Flaming Toros'} league={'Demo'}></LogoButtonWithIcon>
                        <Divider sx={{ borderColor: '#424549' }} />
                    </Container>
                </Container>
            </Box>

            {/* Repeat for more sections as needed */}
        </Container>
    );
}



export default Demo_Home;