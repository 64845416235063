import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { unset } from 'lodash';

// Register necessary chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const PlayerStatChart = ({ memberData, selectedStat }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Aggregate the selected stat for all players
  const aggregatedValue = memberData.reduce((acc, member) => acc + (parseFloat(member[selectedStat]) || 0), 0);

  // Calculate the percentage each player contributes to the total
  const playerPercentages = memberData.map((member) => {
    const value = parseFloat(member[selectedStat]) || 0;
    return ((value / aggregatedValue) * 100).toFixed(2); // Get percentage and round to 2 decimals
  });

  const chartData = {
    labels: memberData.map((member, index) => `${member.Username} (${playerPercentages[index]}%)`),
    datasets: [
      {
        label: `${selectedStat}`,
        data: memberData.map((member) => parseFloat(member[selectedStat]) || 0),
        borderColor: '#2196f3',
        backgroundColor: 'rgba(85, 183, 242, 0.2)',
        pointBackgroundColor: '#1D83D4',
        fill: true,
        tension: 0.4, // Smooth line
        pointRadius: 5,
        pointHoverRadius: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          font: {
            size: 10,
          },
          color: '#B9BABA', // Adjust this value to change the font size for the X-axis

          autoSkip: true, // Automatically skip labels when there are too many
          maxTicksLimit: 10, // Show a maximum of 10 labels
          maxRotation: 90, // Rotate labels to 90 degrees
          minRotation: 70,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          color: '#B9BABA', // Adjust this value to change the font size for the Y-axis

          text: `Total: ${aggregatedValue}`, // Label the Y-axis with the total value
        },
        ticks: {
          color: '#B9BABA', // Adjust this value to change the font size for the Y-axis

          callback: (value) => value, // Ensures values are nicely formatted
        },
        max: aggregatedValue, // Set the Y-axis max to the total sum of the selected stat
      },
    },
    plugins: {
      legend: {
        display: false, // Hides the legend if you don't want multiple datasets to be shown
      },
    },
  };

  return (
    <Box sx={{ marginTop: 5 }}>
      {memberData && memberData.length > 0 ? (
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={{ color: 'white', fontSize: '14px' }}>{selectedStat}</Typography>
          </Box>
          <Box sx={{ minWidth: `${memberData.length * 50}px` }}>
            <Line data={chartData} options={options} height={windowWidth < 600 ? 300 : windowWidth < 900 ? unset : unset} />
          </Box>
        </Box>
      ) : (
        <p>No data available for the selected stat</p>
      )}
    </Box>
  );
};

export default PlayerStatChart;
